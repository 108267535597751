
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Styles
html, body, #app {
    height: 100%;
}

main {
	width: 100%
}

#app {
    // align-items: center;
    // display: flex;
    // justify-content: center;
    padding-top: 100px;
}

.navbar-brand img {
	height: 25px;
}

@media only screen and (max-width : 768px) {
	#app {
		display: block;
		padding-top: 100px;
		padding-bottom: 50px;
		height: auto;
	}
}